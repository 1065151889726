import { SeverityLevel } from "@microsoft/applicationinsights-web";
import axios from "axios";
import { setupAppInsights } from "setupAppInsights";
import { logErrorWithException } from "client/api/LoggingApi";

export class FrontendMonitoring {
  private _appInsights;
  private _eventHandlers: {
    onError?: void;
    onUnhandledRejection?: void;
  } = {};

  constructor() {
    this._appInsights = this.setupAppInsights();
  }

  private setupAppInsights = () => {
    const appInsights = setupAppInsights();
    this.addEventHandlers();
    return appInsights;
  };

  private addEventHandlers = () => {
    this._eventHandlers.onError = window.addEventListener("error", (event) => {
      const { filename, lineno, colno, error } = event;
      error.message = `Uncaught Error: ${error.message}`;
      this._appInsights?.trackException(
        { exception: error, severityLevel: SeverityLevel.Error },
        {
          url: filename,
          errorSrc: `window.onerror@${filename}:${lineno}:${colno}`,
          lineNumber: lineno,
          columnNumber: colno,
        }
      );
      logErrorWithException(`Unhandled Error: ${event}`, JSON.stringify({ filename, lineno, colno, error }));
    });

    this._eventHandlers.onUnhandledRejection = window.addEventListener("unhandledrejection", (event) => {
      const url = axios.isAxiosError(event.reason) ? event.reason.config.url : undefined;

      this._appInsights?.trackException(
        {
          exception: new Error(`Unhandled Rejection: ${event.reason.message}`),
          severityLevel: SeverityLevel.Error,
        },
        {
          typeName: "Unhandled Rejection",
          url,
        }
      );
      logErrorWithException(
        `Unhandled Rejection: ${event.reason.message.toString()}, url: ${url}`,
        JSON.stringify(event)
      );
    });
  };

  private removeEventHandlers = () => {
    this._eventHandlers?.onError && window.removeEventListener("error", this._eventHandlers.onError);
    this._eventHandlers?.onUnhandledRejection &&
      window.removeEventListener("unhandledrejection", this._eventHandlers.onUnhandledRejection);
  };
}
