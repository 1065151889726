import "config/LuxonConfig";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "@mui/material";
import { FrontendMonitoring } from "FrontendMonitoring";
import { ConfigurationProvider } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { AlertProvider } from "components/AlertProvider/AlertProvider";
import { GlobalThemeApplier } from "components/Theme/GlobalThemeApplier";

interface AppProps {
  routes: React.ReactElement;
}

const frontendMonitoring = new FrontendMonitoring();

export function App({ routes }: AppProps) {
  // TODO: When we've implemented darkmode, pass the palette to GlobalThemeApplier's themeName prop
  // const palette = useThemePaletteType();

  // TODO: remove ThemeFromLocalStorage when we have a proper theme switcher
  return (
    <div>
      {/*<ThemeFromLocalStorage>*/}
      <GlobalStyles
        styles={`
        @keyframes fadeInFromLeft {
          from {
            opacity: 0;
            transform: translate(-6px);
          }
          to {
            opacity: 1;
            transform: translate(0px);
          }
        }
        .animate-fade-in-from-left {
          animation: fadeInFromLeft .4s;
        }
      `}
      />
      {/* Temporarily force light mode on, as we not yet implemented dark mode */}
      <GlobalThemeApplier themeName={"light"} />
      <ConfigurationProvider>
        <AlertProvider>
          <BrowserRouter>{routes}</BrowserRouter>
        </AlertProvider>
      </ConfigurationProvider>
      {/*</ThemeFromLocalStorage>*/}
    </div>
  );
}
