import React from "react";
import { List, ListItem, styled, Typography } from "@mui/material";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { inviteUserToSubscriptionGroup } from "client/api/SubscriptionGroupApi";
import type { InviteUserDialogProps } from "components/Dialogs/InviteUserDialog";
import { InviteUserDialog } from "components/Dialogs/InviteUserDialog";

type InviteUserToSubscriptionGroupProps = Pick<InviteUserDialogProps, "show" | "onCancel"> & {
  subscriptionGroupId: string;
  onSubmitComplete: () => Promise<void> | void;
};

const grantValues = [
  {
    label: "Administrator",
    value: "Admin",
  },
  { label: "Technical Manager", value: "Tech" },
  { label: "Billing Manager", value: "Billing" },
] as const;

const StyledList = styled(List)`
  list-style-type: disc;
  padding: 8px;
  padding-left: 16px;
  .MuiListItem-root {
    display: list-item;
    padding: 0;
  }
`;

const GrantsInfo = () => {
  return (
    <>
      <Typography color={"inherit"}>
        <strong>Administrator</strong>
      </Typography>
      <StyledList>
        <ListItem>
          Authenticate to any subscription in this subscription group as part of the Octopus Managers group
        </ListItem>
        <ListItem>Manage billing of any subscription in this subscription group</ListItem>
        <ListItem>Manage configuration of any subscription in this subscription group</ListItem>
        <ListItem>Manage access control for any subscription in this subscription group</ListItem>
        <ListItem>Rename or delete this subscription group</ListItem>
        <ListItem>Manage access control for this subscription group</ListItem>
      </StyledList>
      <Typography color={"inherit"}>
        <strong>Technical Manager</strong>
      </Typography>
      <StyledList>
        <ListItem>
          Authenticate to any subscription in this subscription group as part of the Octopus Managers group
        </ListItem>
        <ListItem>Manage configuration of any subscription in this subscription group</ListItem>
        <ListItem>Manage access control for any subscription in this subscription group</ListItem>
        <ListItem>Rename or delete this subscription group</ListItem>
        <ListItem>Manage access control for this subscription group</ListItem>
      </StyledList>
      <Typography color={"inherit"}>
        <strong>Billing Manager</strong>
      </Typography>
      <StyledList>
        <ListItem>Manage billing of any subscription in this subscription group</ListItem>
      </StyledList>
    </>
  );
};

export function InviteUserToSubscriptionGroup(props: InviteUserToSubscriptionGroupProps) {
  const { show, onCancel, onSubmitComplete, subscriptionGroupId } = props;
  const { userInfo } = useUserContext();

  const onSubmit: InviteUserDialogProps["onSubmit"] = async (email, firstName, lastName, grant) => {
    await inviteUserToSubscriptionGroup(
      subscriptionGroupId,
      email,
      `${firstName} ${lastName}`.trim(),
      `${userInfo?.firstName} ${userInfo?.lastName}`.trim(),
      grant
    );
    onSubmitComplete();
  };

  return (
    <InviteUserDialog
      show={show}
      dialogTitle={"Invite a user to this subscription group"}
      onCancel={onCancel}
      onSubmit={onSubmit}
      grantsInfo={<GrantsInfo />}
      grantValues={grantValues}
    />
  );
}
