import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { envConfig } from "utils/envConfig";

export const setupAppInsights = () => {
  if (
    envConfig.AppInsightsConnectionString &&
    envConfig.AppInsightsConnectionString != "NO_APP_INSIGHTS_CONNECTION_STRING_DEFINED"
  ) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: envConfig.AppInsightsConnectionString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        disableExceptionTracking: true, // we manually collect errors on window error event
        extensions: [reactPlugin],
      },
    });

    appInsights.loadAppInsights();
    return appInsights;
  }
  return undefined;
};
