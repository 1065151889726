import React from "react";
import { List, ListItem, styled, Typography } from "@mui/material";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import type { CloudLicensingChannel, CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { inviteUserToCloudSubscription } from "client/api/CloudSubscriptionApi";
import { InviteUserDialog, type InviteUserDialogProps } from "components/Dialogs/InviteUserDialog";

type InviteUserToCloudSubscriptionProps = Pick<InviteUserDialogProps, "show" | "onCancel"> & {
  cloudSubscription: CloudSubscriptionDto<CloudLicensingChannel>;
  onSubmitComplete: () => Promise<void> | void;
};

const grantValues = ["Everyone (system team)", "Octopus Managers (system team)"] as const;

const StyledList = styled(List)`
  list-style-type: disc;
  padding: 8px;
  padding-left: 16px;
  .MuiListItem-root {
    display: list-item;
    padding: 0;
  }
`;

const GrantsInfo = () => (
  <>
    <Typography color={"inherit"}>
      <strong>Everyone - Cloud Subscription User</strong>
    </Typography>
    <StyledList>
      <ListItem>Authenticate to this subscription as part of the Everyone group</ListItem>
    </StyledList>
    <Typography color={"inherit"}>
      <strong>Octopus Managers - Cloud Subscription Owner</strong>
    </Typography>
    <StyledList>
      <ListItem>Authenticate to this subscription as part of the Octopus Managers group</ListItem>
      <ListItem>Manage billing of this subscription</ListItem>
      <ListItem>Manage configuration of this subscription</ListItem>
      <ListItem>Manage access control for this subscription</ListItem>
    </StyledList>
  </>
);

export function InviteUserToCloudSubscription(props: InviteUserToCloudSubscriptionProps) {
  const { show, onCancel, onSubmitComplete, cloudSubscription } = props;
  const { userInfo } = useUserContext();

  const onSubmit: InviteUserDialogProps["onSubmit"] = async (email, firstName, lastName, grant) => {
    await inviteUserToCloudSubscription(
      cloudSubscription?.id,
      email,
      `${firstName} ${lastName}`.trim(),
      `${userInfo?.firstName} ${userInfo?.lastName}`.trim(),
      grant
    );
    onSubmitComplete();
  };

  return (
    <InviteUserDialog
      show={show}
      dialogTitle={"Invite user to " + cloudSubscription.friendlyName}
      onCancel={onCancel}
      onSubmit={onSubmit}
      grantsInfo={<GrantsInfo />}
      grantValues={grantValues}
    />
  );
}
