import type { ReactNode } from "react";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AlertTitle,
  Autocomplete,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { bold } from "fontWeights";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import { AlertWithShadow } from "components/alert/AlertWithShadow";
import { SubmittableForm } from "components/form/SubmittableForm";

type GrantValue = "Everyone (system team)" | "Octopus Managers (system team)" | "Admin" | "Tech" | "Billing";

type GrantValueOption = { label: string; value: GrantValue };

export type InviteUserDialogProps = {
  show: boolean;
  dialogTitle: string;
  onCancel: () => Promise<void> | void;
  onSubmit: (email: string, firstName: string, lastName: string, grant: string) => Promise<void> | void;
  grantsInfo: ReactNode;
  grantValues: readonly GrantValue[] | readonly GrantValueOption[];
  defaultGrantValue?: GrantValue;
};

const emailOptions = {
  required: {
    value: true,
    message: "Please enter an email address for this user.",
  },
  pattern: {
    value: /^\w(?:\.?[\w%'+-]+)*@\w(?:[\w-]*\.)+?[a-z]{2,}$/i,
    message: "Please enter a valid email address.",
  },
  maxLength: {
    value: 255,
    message: "Email address is too long, please enter an email address with less than 255 characters.",
  },
  minLength: {
    value: 4,
    message: "Email address is too short, please enter an email address with at least 4 characters.",
  },
};

const firstNameOptions = {
  required: {
    value: true,
    message: "Please enter a first name for this user.",
  },
  maxLength: {
    value: 30,
    message: "First name too long, please enter a name with less than 30 characters.",
  },
  validate: {
    notEmpty: (val: string) => !!val.trim() || "Please enter a first name for this user.",
  },
};

const lastNameOptions = {
  maxLength: {
    value: 30,
    message: "Last name too long, please enter a name with less than 30 characters.",
  },
  validate: {
    notEmpty: (val?: string) => !val || !!val.trim() || "Please enter a last name for this user.",
  },
};

const isGrantValueOption = (x: unknown): x is GrantValueOption => {
  return typeof x === "object" && x !== null && "label" in x && "value" in x;
};

export function InviteUserDialog(props: InviteUserDialogProps) {
  const { show, dialogTitle, onCancel, onSubmit, grantsInfo, grantValues, defaultGrantValue } = props;
  const [showGrantsInfo, setShowGrantsInfo] = React.useState(false);
  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onCancel}
    >
      <DialogTitle id="alert-dialog-title">
        {dialogTitle}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p style={{ margin: 0, marginBottom: "16px", position: "relative", zIndex: "100" }}>
          <ExternalLink href={ExternalUriMap.octopusComDocsCloudInvitingUsersPath}>Visit the docs</ExternalLink> to
          learn about inviting users.
        </p>
        <SubmittableForm
          submitAction={async (data) => {
            const chosenValue = grantValues.find((x) => isGrantValueOption(x) && x.label === data.grant);
            const grant = isGrantValueOption(chosenValue) ? chosenValue.value : data.grant;
            await onSubmit(data.email, data.firstName, data.lastName, grant);
          }}
          defaultValues={{
            email: "",
            firstName: "",
            lastName: "",
            grant:
              defaultGrantValue || (isGrantValueOption(grantValues[0]) ? grantValues[0]?.value : grantValues[0]) || "",
          }}
          submitLabel={"Invite"}
        >
          {(formController) => {
            const { register, formState } = formController;
            return (
              <Grid container sx={{ pl: 3, pt: 3 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: bold }}>User details</Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      id={"email"}
                      required
                      fullWidth
                      label={"Email address"}
                      error={!!formState?.errors?.email}
                      helperText={formState?.errors?.email?.message}
                      {...register("email", emailOptions)}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6} sx={{ pr: 0.5 }}>
                    <TextField
                      id={"firstName"}
                      required
                      fullWidth
                      label={"First name"}
                      error={!!formState?.errors?.firstName}
                      helperText={formState?.errors?.firstName?.message}
                      {...register("firstName", firstNameOptions)}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pl: 0.5 }}>
                    <TextField
                      id={"lastName"}
                      fullWidth
                      label={"Last name"}
                      error={!!formState?.errors?.lastName}
                      helperText={formState?.errors?.lastName?.message}
                      {...register("lastName", lastNameOptions)}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: bold }}>Team</Typography>
                  </Grid>
                  <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={grantValues}
                        defaultValue={defaultGrantValue || grantValues[0]}
                        disableClearable
                        isOptionEqualToValue={(
                          option: GrantValue | GrantValueOption,
                          value: GrantValue | GrantValueOption
                        ) => {
                          if (isGrantValueOption(option) && isGrantValueOption(value)) {
                            return option.value === value.value;
                          }
                          if (isGrantValueOption(option) && typeof value === "string") {
                            return option.value === value;
                          }
                          return option === value;
                        }}
                        getOptionLabel={(x) => {
                          if (isGrantValueOption(x)) {
                            return x.label;
                          }
                          return x;
                        }}
                        renderInput={(params) => <TextField required {...params} {...register("grant")} />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}>
                    <AlertWithShadow
                      severity={"info"}
                      onClick={() => setShowGrantsInfo(!showGrantsInfo)}
                      action={
                        <IconButton aria-label="expand" color="inherit">
                          {showGrantsInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      }
                    >
                      <AlertTitle>What does each team include?</AlertTitle>
                      <Collapse in={showGrantsInfo}>{grantsInfo}</Collapse>
                    </AlertWithShadow>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </SubmittableForm>
      </DialogContent>
    </Dialog>
  );
}
